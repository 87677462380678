import React from "react"
import { Box, Text } from "@chakra-ui/core"
import { Heading1 } from "../../headings/Heading1"

type Props = {
  title: string
  message: string
}

export const ContactLayout: React.FC<Props> = ({
  children,
  title,
  message,
}) => {
  return (
    <Box bg="#A8DADC" color="black" paddingX="2rem" paddingY="1rem">
      <Box textAlign="center" p={["0.5rem", "1rem"]} minHeight="76vh">
        <Heading1>{title} </Heading1>
        <Box marginY="3rem">
          <Text fontWeight="400" fontSize="1.1rem">
            {message}
          </Text>
        </Box>
        <Box display="flex" justifyContent="center" paddingX="1rem">
          {children}
        </Box>
      </Box>
    </Box>
  )
}
