import React from "react"
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Textarea,
  Button,
  Box,
} from "@chakra-ui/core"

export const ContactForm = () => {
  return (
    <form
      name="contact v2"
      method="POST"
      data-netlify="true"
      action="/kiitos"
      netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact v2" />
      <FormControl paddingY="2rem" paddingX="2rem">
        <Box hidden>
          <label>
            Ihminen, sinä et näe tätä: <input name="bot-field" />
          </label>
        </Box>
        <FormLabel htmlFor="email">Sähköposti</FormLabel>
        <Input
          type="email"
          name="email"
          aria-describedby="email-helper-text"
          isRequired
        />
        <FormHelperText id="email-helper-text">
          Emme koskaan jaa sähköpostiosoittasi muille
        </FormHelperText>
        <FormLabel htmlFor="name">Nimi</FormLabel>
        <Input
          type="text"
          name="name"
          aria-describedby="name-helper-text"
          isRequired
        />
        <FormLabel htmlFor="topic">Asia</FormLabel>
        <Textarea
          isRequired
          type="textarea"
          name="topic"
          aria-describedby="topic-helper-text"
        />
        <FormHelperText id="topic-helper-text">
          Kerro meille mitä on mielessäsi...
        </FormHelperText>
        <Button
          mt={4}
          cursor="pointer"
          bg="primary"
          color="white"
          variant="outline"
          type="submit"
        >
          Lähetä
        </Button>
      </FormControl>
    </form>
  )
}
