import React from "react"
import { Layout } from "../components/layout/Layout"

import { ContactLayout } from "../components/contact/layout/ContactLayout"
import { ContactForm } from "../components/contact/layout/ContactForm"

const otaYhteytta = () => {
  return (
    <Layout>
      <ContactLayout
        title="Anna palautetta"
        message="Onko sinulla parannusehdotuksia, haluat tehdä yhteistyötä tai tahdot muusta syystä olla meihin yhteydessä? Tee se tällä lomakkeella."
      >
        <ContactForm />
      </ContactLayout>
    </Layout>
  )
}

export default otaYhteytta
